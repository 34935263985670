
import { defineComponent, PropType } from "vue";
import { Character } from "@/services/ApiService/Interfaces/Character";
import { mapActions, mapGetters } from "vuex";
import {
  ADD_TO_FAVOURITES,
  FAVOURITES_IDS,
  REMOVE_FROM_FAVOURITES,
} from "@/store/types";

export default defineComponent({
  name: "CharacterCard",
  props: {
    character: {
      type: Object as PropType<Character>,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      favouritesIds: FAVOURITES_IDS,
    }),
    alreadyInList(): boolean {
      return this.favouritesIds.some(
        (item: number) => item === this.character.id
      );
    },
  },
  methods: {
    ...mapActions({
      addToFavourites: ADD_TO_FAVOURITES,
      removeFromFavourites: REMOVE_FROM_FAVOURITES,
    }),
    watchCharacter() {
      this.$router.push({
        path: `/${this.character.id}`,
      });
    },
  },
});
