
import { defineComponent } from "vue";
import HeartIcon from "@/components/Header/HeartIcon.vue";
import { mapGetters } from "vuex";
import { FAVOURITES_IDS } from "@/store/types";

export default defineComponent({
  name: "Header",
  components: {
    HeartIcon,
  },
  data() {
    return {
      count: 0,
    };
  },
  filters: {
    favourites(value: number[]) {
      return value.length;
    },
  },
  computed: {
    ...mapGetters({
      favourites: FAVOURITES_IDS,
    }),
    favouritesCount(): number {
      return this.favourites.length;
    },
  },
});
