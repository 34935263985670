
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  ERROR,
  FAVOURITES,
  FAVOURITES_IDS,
  GET_FAVOURITES,
  GET_FAVOURITES_IDS,
  LOADING,
} from "@/store/types";
import Loader from "@/components/Loader/Loader.vue";
import CharacterCard from "@/components/CharacterCard/CharacterCard.vue";
export default defineComponent({
  name: "CharactersFavorite",
  components: {
    CharacterCard,
    Loader,
  },
  computed: {
    ...mapGetters({
      favourites: FAVOURITES,
      favouritesId: FAVOURITES_IDS,
      error: ERROR,
      loading: LOADING,
    }),
    favouritesLength(): number {
      return this.favouritesId?.length || 0;
    },
  },
  methods: {
    ...mapActions({
      getFavouritesIds: GET_FAVOURITES_IDS,
      getFavourites: GET_FAVOURITES,
    }),
  },
  mounted() {
    this.getFavouritesIds();
    this.getFavourites();
  },
});
