
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import {
  ADD_TO_FAVOURITES,
  CHARACTER,
  ERROR,
  FAVOURITES_IDS,
  GET_CHARACTER,
  LOADING,
  REMOVE_FROM_FAVOURITES,
} from "@/store/types";
import Loader from "@/components/Loader/Loader.vue";
export default defineComponent({
  name: "CharacterDetail",
  components: {
    Loader,
  },
  computed: {
    ...mapGetters({
      character: CHARACTER,
      loading: LOADING,
      error: ERROR,
      favouritesIds: FAVOURITES_IDS,
    }),
    episode(): string {
      return this.character.episode[0];
    },
    alreadyInList(): boolean {
      return this.favouritesIds.some(
        (item: number) => item === this.character.id
      );
    },
    characterLoaded(): boolean {
      return this.character !== null;
    },
  },
  watch: {
    loading(value) {
      console.log(value);
    },
  },
  methods: {
    ...mapActions({
      getCharacter: GET_CHARACTER,
      addToFavourites: ADD_TO_FAVOURITES,
      removeFromFavourites: REMOVE_FROM_FAVOURITES,
    }),
  },
  async mounted() {
    const id = this.$route.params.id;
    await this.getCharacter(id);
  },
});
