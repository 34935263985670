
import { defineComponent } from "vue";

export default defineComponent({
  name: "Pagination",
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    changePage(result: number) {
      if (
        (result === 1 && this.currentPage === 1) ||
        (result === this.max && this.currentPage === this.max)
      ) {
        return;
      }
      this.$emit("changePage", result);
    },
  },
  computed: {
    countMinus(): number {
      return this.currentPage !== 1 ? this.currentPage - 1 : 1;
    },
    countPlus(): number {
      return this.currentPage !== this.max ? this.currentPage + 1 : this.max;
    },
  },
});
